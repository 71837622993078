import React, { useRef } from 'react'
import { CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react';

const Toaster = ({ notifications }) => {
  let toasters = useRef({})
  let options = {
    position: 'top-right', autohide: 3000, fade: true
  }
  let toasts = notifications || [];
  toasters.current = {
    ...(() => {
      return toasts.reduce((toasters, toast) => {
        toasters[options.position] = toasters[options.position] || []
        toasters[options.position].push({ ...toast, ...options })
        return toasters
      }, {})
    })()
  };

  return (
    <>
      {Object.keys(toasters.current).map((toasterKey) => (
        <CToaster
          position={toasterKey}
          key={'toaster' + toasterKey}
        >
          {
            toasters.current[toasterKey].map((toast, key) => {
              return (
                <CToast
                  key={'toast' + key}
                  show={true}
                  autohide={toast.autohide}
                  fade={toast.fade}
                  color={toast.color}
                >
                  <CToastHeader closeButton={toast.closeButton}>
                    {toast.title || 'Message'}
                  </CToastHeader>
                  <CToastBody>
                    {toast.message || '-'}
                  </CToastBody>
                </CToast>
              )
            })
          }
        </CToaster>
      ))}
    </>
  )
}

export default Toaster
