import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilUser,
  cilPeople,
  cilCalculator,
  cilGlobeAlt,
  cilCreditCard,
  cilWallet,
  cilReload,
  cilExposure,
  cilSettings,
  cilList,
  cilLockLocked,
  cilGrid,
  cilFilter,
  cilBank,
  cilWarning,
  cilCheckCircle,
  cilLockUnlocked,
  cilLan,
  cilSearch,
  cilFilterX,
  cilNotes,
  cilTrash,
  cilPencil,
  cilCloudDownload,
  cilCalendar,
  cilUserFemale,
  cilLocationPin,
  cilOptions,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cifPl,
  cifEs,
  cifFr,
  cifIn,
  cifBr,
  cifUs,
  cibCcVisa,
  cibCcMastercard,
  cibCcAmex,
  cibGooglePay,
  cibPaypal,
  cibStripe,
  cilMoodGood,
  cilMoodVeryGood,
  cilMeh,
  cilMoodBad,
  cilUserPlus,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilEnvelopeClosed,
  cilBell,
  cilSpeech,
  cilContact,
  cilChatBubble,
  cilMobile,
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
    cilUser,
    cilPeople,
    cilCalculator,
    cilGlobeAlt,
    cilCreditCard,
    cilWallet,
    cilReload,
    cilExposure,
    cilSettings,
    cilList,
    cilLockLocked,
    cilGrid,
    cilFilter,
    cilBank,
    cilWarning,
    cilCheckCircle,
    cilLockUnlocked,
    cilLan,
    cilSearch,
    cilFilterX,
    cilNotes,
    cilTrash,
    cilPencil,
    cilCloudDownload,
    cilCalendar,
    cilUserFemale,
    cilLocationPin,
    cilOptions,
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cifPl,
    cifEs,
    cifFr,
    cifIn,
    cifBr,
    cifUs,
    cibCcVisa,
    cibCcMastercard,
    cibCcAmex,
    cibGooglePay,
    cibPaypal,
    cibStripe,
    cilMoodGood,
    cilMoodVeryGood,
    cilMeh,
    cilMoodBad,
    cilUserPlus,
    cilUserFollow,
    cilUserUnfollow,
    cilX,
    cilEnvelopeClosed,
    cilBell,
    cilSpeech,
    cilContact,
    cilChatBubble,
    cilMobile,
  },
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
  }
);
